@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  overflow: hidden;
}

._nodrag,
img,
svg {
  -webkit-user-drag: none;
  user-drag: none;
}

/* width */
.scrollbar-thin::-webkit-scrollbar {
  width: 10px;
  height: 16px;
}

/* Track */
.scrollbar-thin::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #304451;
}

/* Handle */
.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #dbe5e0;
  border-radius: 100vh;
  border: 2px solid #304451;
  cursor: grab;
}

/* Handle on hover */
.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #cad4d0;
  cursor: grab;
}

/** mac pro styles **/

.computer-container {
  position: relative;
}

.computer-monitor {
  margin: 0 auto;
  background-color: #1e3342;
  border: 20px solid #546e7a;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
}

.computer-bottom {
  position: relative;
  z-index: 1;
  box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  background-color: #d2d6d9;
  height: 75px;
}

.computer-stand {
  box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 0;
  margin: 0 auto;
  height: 50px;
  width: 200px;
  background-color: #c0c5c9;
  clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);
}

.computer-stand-bottom {
  box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  height: 25px;
  width: 250px;
  background-color: #d2d6d9;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
}
