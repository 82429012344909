@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: IBM Plex Sans, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.-top-1 {
  top: -.25rem;
}

.-bottom-10 {
  bottom: -2.5rem;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-2 {
  right: .5rem;
}

.bottom-2 {
  bottom: .5rem;
}

.top-1\/2 {
  top: 50%;
}

.-top-8 {
  top: -2rem;
}

.left-3 {
  left: .75rem;
}

.top-3 {
  top: .75rem;
}

.left-6 {
  left: 1.5rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.float-right {
  float: right;
}

.m-2 {
  margin: .5rem;
}

.m-auto {
  margin: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-1\/5 {
  height: 20%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-full {
  height: 100%;
}

.h-8 {
  height: 2rem;
}

.h-screen {
  height: 100vh;
}

.h-6 {
  height: 1.5rem;
}

.h-20 {
  height: 5rem;
}

.h-12 {
  height: 3rem;
}

.h-2 {
  height: .5rem;
}

.h-10 {
  height: 2.5rem;
}

.h-\[40px\] {
  height: 40px;
}

.h-1\/2 {
  height: 50%;
}

.h-\[calc\(100vh_-_40px\)\] {
  height: calc(100vh - 40px);
}

.h-5 {
  height: 1.25rem;
}

.h-9 {
  height: 2.25rem;
}

.h-4\/5 {
  height: 80%;
}

.h-4 {
  height: 1rem;
}

.h-7 {
  height: 1.75rem;
}

.min-h-full {
  min-height: 100%;
}

.w-3\/6 {
  width: 50%;
}

.w-max {
  width: max-content;
}

.w-1\/4 {
  width: 25%;
}

.w-8 {
  width: 2rem;
}

.w-screen {
  width: 100vw;
}

.w-\[80px\] {
  width: 80px;
}

.w-full {
  width: 100%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-12 {
  width: 3rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-\[10\%\] {
  width: 10%;
}

.w-\[90\%\] {
  width: 90%;
}

.w-3\/4 {
  width: 75%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-5 {
  width: 1.25rem;
}

.w-9 {
  width: 2.25rem;
}

.w-7 {
  width: 1.75rem;
}

.w-6 {
  width: 1.5rem;
}

.w-1\/5 {
  width: 20%;
}

.w-4\/5 {
  width: 80%;
}

.flex-grow {
  flex-grow: 1;
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-accountingGreen-light {
  --tw-border-opacity: 1;
  border-color: rgb(11 234 185 / var(--tw-border-opacity));
}

.border-accountingGreen-base {
  --tw-border-opacity: 1;
  border-color: rgb(19 156 134 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-accountingBlue-light {
  --tw-border-opacity: 1;
  border-color: rgb(48 68 81 / var(--tw-border-opacity));
}

.border-accountingBlue-base {
  --tw-border-opacity: 1;
  border-color: rgb(30 51 66 / var(--tw-border-opacity));
}

.border-accountingBlue-dark {
  --tw-border-opacity: 1;
  border-color: rgb(15 26 33 / var(--tw-border-opacity));
}

.border-accountingGray-light {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 217 / var(--tw-border-opacity));
}

.border-accountingGray-base {
  --tw-border-opacity: 1;
  border-color: rgb(155 164 171 / var(--tw-border-opacity));
}

.bg-accountingGreen-light {
  --tw-bg-opacity: 1;
  background-color: rgb(11 234 185 / var(--tw-bg-opacity));
}

.bg-accountingBlue-light {
  --tw-bg-opacity: 1;
  background-color: rgb(48 68 81 / var(--tw-bg-opacity));
}

.bg-accountingBlue-base {
  --tw-bg-opacity: 1;
  background-color: rgb(30 51 66 / var(--tw-bg-opacity));
}

.bg-white\/70 {
  background-color: #ffffffb3;
}

.bg-black\/50 {
  background-color: #00000080;
}

.bg-accountingGreen-base {
  --tw-bg-opacity: 1;
  background-color: rgb(19 156 134 / var(--tw-bg-opacity));
}

.bg-accountingGreen-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(25 103 100 / var(--tw-bg-opacity));
}

.bg-accountingBlue-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(15 26 33 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.from-blue-900 {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-to: #1e3a8a00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-teal-100 {
  --tw-gradient-to: #ccfbf1;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-white {
  fill: #fff;
}

.fill-accountingGreen-light {
  fill: #0beab9;
}

.fill-accountingBlue-light {
  fill: #304451;
}

.fill-accountingBlue-dark {
  fill: #0f1a21;
}

.fill-accountingGreen-base {
  fill: #139c86;
}

.fill-accountingGray-base {
  fill: #9ba4ab;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-1 {
  padding: .25rem;
}

.p-8 {
  padding: 2rem;
}

.p-3 {
  padding: .75rem;
}

.p-24 {
  padding: 6rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.font-bold {
  font-weight: 700;
}

.font-thin {
  font-weight: 100;
}

.font-semibold {
  font-weight: 600;
}

.font-extralight {
  font-weight: 200;
}

.uppercase {
  text-transform: uppercase;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-normal {
  line-height: 1.5;
}

.leading-snug {
  line-height: 1.375;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-accountingBlue-dark {
  --tw-text-opacity: 1;
  color: rgb(15 26 33 / var(--tw-text-opacity));
}

.text-accountingGreen-light {
  --tw-text-opacity: 1;
  color: rgb(11 234 185 / var(--tw-text-opacity));
}

.text-accountingBlue-base {
  --tw-text-opacity: 1;
  color: rgb(30 51 66 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-accountingGray-light {
  --tw-text-opacity: 1;
  color: rgb(210 214 217 / var(--tw-text-opacity));
}

.opacity-50 {
  opacity: .5;
}

.opacity-100 {
  opacity: 1;
}

.opacity-80 {
  opacity: .8;
}

.opacity-20 {
  opacity: .2;
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

* {
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  overflow: hidden;
}

._nodrag, img, svg {
  -webkit-user-drag: none;
  user-drag: none;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 10px;
  height: 16px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #304451;
  border-radius: 100vh;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  cursor: grab;
  background: #dbe5e0;
  border: 2px solid #304451;
  border-radius: 100vh;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  cursor: grab;
  background: #cad4d0;
}

.computer-container {
  position: relative;
}

.computer-monitor {
  background-color: #1e3342;
  border: 20px solid #546e7a;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  margin: 0 auto;
}

.computer-bottom {
  z-index: 1;
  height: 75px;
  background-color: #d2d6d9;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  margin: 0 auto;
  position: relative;
  box-shadow: 5px 5px 11px #00000040;
}

.computer-stand {
  z-index: 0;
  height: 50px;
  width: 200px;
  clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);
  background-color: #c0c5c9;
  margin: 0 auto;
  position: relative;
  box-shadow: 5px 5px 11px #00000040;
}

.computer-stand-bottom {
  height: 25px;
  width: 250px;
  background-color: #d2d6d9;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  margin: 0 auto;
  box-shadow: 5px 5px 11px #00000040;
}

.first\:rounded-tl-lg:first-child {
  border-top-left-radius: .5rem;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-accountingGreen-base:hover {
  --tw-border-opacity: 1;
  border-color: rgb(19 156 134 / var(--tw-border-opacity));
}

.hover\:bg-accountingBlue-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(15 26 33 / var(--tw-bg-opacity));
}

.hover\:bg-accountingGreen-light\/50:hover {
  background-color: #0beab980;
}

.hover\:bg-accountingGreen-base:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(19 156 134 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-50:hover {
  --tw-bg-opacity: .5;
}

.hover\:fill-accountingGreen-base:hover {
  fill: #139c86;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.group:hover .group-hover\:fill-accountingGreen-light {
  fill: #0beab9;
}

@media (min-width: 640px) {
  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:h-\[60px\] {
    height: 60px;
  }

  .lg\:h-\[calc\(100vh_-_60px\)\] {
    height: calc(100vh - 60px);
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 1280px) {
  .xl\:h-\[90px\] {
    height: 90px;
  }

  .xl\:h-\[calc\(100vh_-_100px\)\] {
    height: calc(100vh - 100px);
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:h-\[100px\] {
    height: 100px;
  }

  .\32 xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
}

/*# sourceMappingURL=index.7670f2ae.css.map */
